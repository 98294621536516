import React from "react"
import { Link } from "gatsby"
import { t } from "@lingui/macro"
import { I18nProvider, I18n } from "@lingui/react"
import catalogEs from "../locales/es/messages"
import catalogBr from "../locales/br/messages"
import catalogFr from "../locales/fr/messages"
import catalogIt from "../locales/it/messages"
import catalogDe from "../locales/de/messages"
const letters = [
  "#",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
]

export default props => (
  <>
    <I18nProvider
      language={props.lang}
      catalogs={{
        es: catalogEs,
        it: catalogIt,
        de: catalogDe,
        br: catalogBr,
        fr: catalogFr,
      }}
    >
      <div className="page-header theme-bg-dark position-relative">
        <div className="container d-none d-lg-block">
          <div className="row pt-1 d-block mx-auto text-center">
            {letters.map(letter => {
              return (
                <span>
                  <I18n>
                    {({ i18n }) => (
                      <Link
                        to={i18n._(t`/que-es`)+"#"+letter}
                        className="page-heading single-col-max mx-auto"
                        title={letter}
                      >
                        <strong>{letter}</strong>
                      </Link>
                    )}
                  </I18n>
                  &nbsp;
                </span>
              )
            })}
          </div>
        </div>
      </div>
    </I18nProvider>
  </>
)
