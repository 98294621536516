import React from "react"
import { Link } from "gatsby"
import logo from "../assets/images/site-logo.png"
import "../assets/fontawesome/css/all.min.css"
import { Trans, t } from "@lingui/macro"
import { I18nProvider, I18n } from "@lingui/react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import catalogEs from "../locales/es/messages"
import catalogBr from "../locales/br/messages"
import catalogFr from "../locales/fr/messages"
import catalogIt from "../locales/it/messages"
import catalogDe from "../locales/de/messages"

export default props => (
  <>
    <I18nProvider
      language={props.lang}
      catalogs={{
        es: catalogEs,
        it: catalogIt,
        de: catalogDe,
        br: catalogBr,
        fr: catalogFr,
      }}
    >
      <header className="header fixed-top">
        <div className="branding">
          <div className="container-fluid position-relative">
            <nav className="navbar navbar-expand-lg">
              <div className="site-logo">
                <I18n>
                  {({ i18n }) => (
                    <Link
                      to={i18n._(t`/`)}
                      className="navbar-brand"
                      title="startupedia.net"
                    >
                      <img
                        className="logo-icon mr-2"
                        src={logo}
                        alt="logo"
                        title="logo"
                        style={{ height: "45px" }}
                      />
                      <span className="logo-text">Startupedia</span>
                    </Link>
                  )}
                </I18n>

                {/*<Link to="/" className="navbar-brand" title="startupedia.net">
                  <img
                    className="logo-icon mr-2"
                    src={logo}
                    alt="logo"
                    title="logo"
                    style={{ height: "45px" }}
                  />
                  <span className="logo-text">Startupedia</span>
                </Link>*/}
              </div>

              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navigation"
                aria-controls="navigation"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span> </span>
                <span> </span>
                <span> </span>
              </button>

              <div
                className="collapse navbar-collapse py-3 py-lg-0"
                id="navigation"
              >
                <ul className="social-list list-inline mt-3 mt-lg-0 mb-lg-0 d-none d-xl-flex ml-lg-3 mr-lg-3">
                  <li className="list-inline-item">
                    <OutboundLink
                      href="https://www.youtube.com/channel/UCOGxgHssFrABTVCDS_OEkOg?sub_confirmation=1"
                      target="_blank"
                      rel="noopener noreferrer"
                      alt="Youtube startupedia.net"
                      title="Youtube startupedia.net"
                    >
                      <i className="fab fa-youtube fa-fw"></i>
                    </OutboundLink>
                  </li>
                  <li className="list-inline-item">
                    <OutboundLink
                      href="https://twitter.com/startupedianet"
                      target="_blank"
                      rel="noopener noreferrer"
                      alt="Twitter startupedia.net"
                      title="Twitter statupedia.net"
                    >
                      <i className="fab fa-twitter fa-fw"></i>
                    </OutboundLink>
                  </li>
                </ul>
                <ul className="navbar-nav ml-lg-auto">
                  <li className="nav-item mr-lg-4">
                    <I18n>
                      {({ i18n }) => (
                        <Link
                          to={i18n._(t`/blog`)}
                          title={i18n._(t`Blog`)}
                          className="nav-link"
                          activeStyle={{ color: "black" }}
                        >
                          <Trans>Blog</Trans>
                        </Link>
                      )}
                    </I18n>
                  </li>
                  <li className="nav-item mr-lg-4">
                    <I18n>
                      {({ i18n }) => (
                        <Link
                          to={i18n._(t`/que-es`)}
                          title={i18n._(t`¿Qué es...?`)}
                          className="nav-link"
                          activeStyle={{ color: "black" }}
                        >
                          <Trans>¿Qué es...?</Trans>
                        </Link>
                      )}
                    </I18n>
                  </li>
                  <li className="nav-item mr-lg-4">
                    <I18n>
                      {({ i18n }) => (
                        <Link
                          to={i18n._(t`/quien-es`)}
                          title={i18n._(t`¿Quién es...?`)}
                          className="nav-link"
                          activeStyle={{ color: "black" }}
                        >
                          <Trans>¿Quién es...?</Trans>
                        </Link>
                      )}
                    </I18n>
                  </li>
                  <li className="nav-item mr-lg-4">
                    <I18n>
                      {({ i18n }) => (
                        <Link
                          to={i18n._(t`/contacto`)}
                          title={i18n._(t`Contacto`)}
                          className="nav-link"
                          activeStyle={{ color: "black" }}
                        >
                          <Trans>Contacto</Trans>
                        </Link>
                      )}
                    </I18n>
                  </li>

                  <li className="nav-item dropdown mr-lg-4">
                    <OutboundLink
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <Trans>Idiomas</Trans>
                    </OutboundLink>
                    <div
                      className="dropdown-menu dropdown-menu-right rounded shadow menu-animate slideIn"
                      aria-labelledby="navbarDropdown"
                    >
                      {props.lang !== "es" && (
                        <I18n>
                          {({ i18n }) => (
                            <Link
                              to="/"
                              className="dropdown-item"
                              title={i18n._(t`Español`)}
                            >
                              <Trans>Español</Trans>
                            </Link>
                          )}
                        </I18n>
                      )}
                      {props.lang !== "it" && (
                        <I18n>
                          {({ i18n }) => (
                            <Link
                              to="/it/"
                              className="dropdown-item"
                              title={i18n._(t`Italiano`)}
                            >
                              <Trans>Italiano</Trans>
                            </Link>
                          )}
                        </I18n>
                      )}
                      {props.lang !== "de" && (
                        <I18n>
                          {({ i18n }) => (
                            <Link
                              to="/de/"
                              className="dropdown-item"
                              title={i18n._(t`Alemán`)}
                            >
                              <Trans>Alemán</Trans>
                            </Link>
                          )}
                        </I18n>
                      )}
                      {props.lang !== "br" && (
                        <I18n>
                          {({ i18n }) => (
                            <Link
                              to="/br/"
                              className="dropdown-item"
                              title={i18n._(t`Portugués`)}
                            >
                              <Trans>Portugués</Trans>
                            </Link>
                          )}
                        </I18n>
                      )}
                      {props.lang !== "fr" && (
                        <I18n>
                          {({ i18n }) => (
                            <Link
                              to="/fr/"
                              className="dropdown-item"
                              title={i18n._(t`Francés`)}
                            >
                              <Trans>Francés</Trans>
                            </Link>
                          )}
                        </I18n>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </I18nProvider>
  </>
)
