import React from "react"
import Navigation from "../components/navigation"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
//import SchemaOrg from "../components/SEO/SchemaOrg"

export default ({
  datePublished = datePublished || undefined,
  dateModified = dateModified || undefined,
  children,
  lang,
  title,
  description = description || "Startupedia, la Enciclopedia sobre STARTUPS y EMPRENDIMIENTO.",
  image,
  paths,
  pageType,
}) => {
  console.log(description)
  const url = `https://startupedia.net${paths[lang]}`

  let schema = {}
  switch (pageType) {
    case "home":
      {
        schema = {
          "@context": "http://schema.org",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": url + "/#website",
              url: url+"/",
              name: "Startupedia",
              description: description,
              inLanguage: {
                "@type": "Language",
                name: getLangName(lang),
              },
            },
            {
              "@type": "WebPage",
              "@id": url + "/#webpage",
              mainEntityOfPage: {
                "@type": "Webpage",
              },
              headline: "Startupedia",
              publisher: {
                "@type": "Organization",
                name: "Startupedia",
                url: url+"/",
                logo: {
                  "@type": "ImageObject",
                  "@id": url + "/#logo",
                  url:
                    "https://startupedia.net/static/site-logo-a1aeacccc0ab8eda7d7f98e85d360c82.png",
                  width: 1000,
                  height: 1000,
                  caption: "Startupedia",
                },
                image: { "@id": url + "/#logo" },
                brand: "Startupedia",
                sameAs: [
                  "https://www.youtube.com/channel/UCOGxgHssFrABTVCDS_OEkOg?sub_confirmation=1",
                  "https://twitter.com/startupedianet",
                ],
              },
              url: url+"/",
              isPartOf: { "@id": url + "/#website" },
              description: description,
              inLanguage: {
                "@type": "Language",
                name: getLangName(lang),
              },
            },
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              name: "Startupedia",
              url: url+"/",
              logo: {
                "@type": "ImageObject",
                url:
                  "https://startupedia.net/static/site-logo-a1aeacccc0ab8eda7d7f98e85d360c82.png",
                width: 1000,
                height: 1000,
              },
              brand: "Startupedia",
              sameAs: [
                "https://www.youtube.com/channel/UCOGxgHssFrABTVCDS_OEkOg?sub_confirmation=1",
                "https://twitter.com/startupedianet",
              ],
              ContactPoint: {
                "@type": "ContactPoint",
                contactType: "customer support",
                url: getContact(lang),
                email: "startupedia.oficial@gmail.com",
                availableLanguage: getLangName(lang),
              },
            },
          ],
        }
      }
      break
    case "blog":
      {
        schema = {
          "@context": "http://schema.org",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": url + "/#website",
              url: url+"/",
              name: "Startupedia",
              description: description,
              inLanguage: {
                "@type": "Language",
                name: getLangName(lang),
              },
            },
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              name: "Startupedia",
              url: url+"/",
              logo: {
                "@type": "ImageObject",
                url:
                  "https://startupedia.net/static/site-logo-a1aeacccc0ab8eda7d7f98e85d360c82.png",
                width: 1000,
                height: 1000,
              },
              brand: "Startupedia",
              sameAs: [
                "https://www.youtube.com/channel/UCOGxgHssFrABTVCDS_OEkOg?sub_confirmation=1",
                "https://twitter.com/startupedianet",
              ],
              ContactPoint: {
                "@type": "ContactPoint",
                contactType: "customer support",
                url: getContact(lang),
                email: "startupedia.oficial@gmail.com",
                availableLanguage: getLangName(lang),
              },
            },
            {
              "@type": "CollectionPage",
              "@id": url + "/#webpage",
              url: url+"/",
              inLanguage: {
                "@type": "Language",
                name: getLangName(lang),
              },
              name: description,
              isPartOf: { "@id": url + "/#website" },
              description: description,
            },
          ],
        }
      }
      break
    case "contact":
    case "people":
    case "terms":
      {
        schema = {
          "@context": "http://schema.org",
          "@graph": [
            {
              "@type": "WebPage",
              "@id": url + "/#webpage",
              mainEntityOfPage: {
                "@type": "Webpage",
              },
              headline: "Startupedia",
              publisher: {
                "@type": "Organization",
                name: "Startupedia",
                url: url+"/",
                logo: {
                  "@type": "ImageObject",
                  "@id": url + "/#logo",
                  url:
                    "https://startupedia.net/static/site-logo-a1aeacccc0ab8eda7d7f98e85d360c82.png",
                  width: 1000,
                  height: 1000,
                  caption: "Startupedia",
                },
                image: { "@id": url + "/#logo" },
                brand: "Startupedia",
                sameAs: [
                  "https://www.youtube.com/channel/UCOGxgHssFrABTVCDS_OEkOg?sub_confirmation=1",
                  "https://twitter.com/startupedianet",
                ],
              },
              url: url+"/",
              description: description,
              inLanguage: {
                "@type": "Language",
                name: getLangName(lang),
              },
            },
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              name: "Startupedia",
              url: url+"/",
              logo: {
                "@type": "ImageObject",
                url:
                  "https://startupedia.net/static/site-logo-a1aeacccc0ab8eda7d7f98e85d360c82.png",
                width: 1000,
                height: 1000,
              },
              brand: "Startupedia",
              sameAs: [
                "https://www.youtube.com/channel/UCOGxgHssFrABTVCDS_OEkOg?sub_confirmation=1",
                "https://twitter.com/startupedianet",
              ],
              ContactPoint: {
                "@type": "ContactPoint",
                contactType: "customer support",
                url: getContact(lang),
                email: "startupedia.oficial@gmail.com",
                availableLanguage: getLangName(lang),
              },
            },
          ],
        }
      }
      break
    case "blog-post":
    case "people-post":
    case "term-post":
      {
        schema = {
          "@context": "http://schema.org",

          "@type": "Article",
          headline: title,
          image: image,
          datePublished: datePublished,
          dateModified: dateModified,
          author: {
            "@type": "Person",
            name: "Luis Juarros",
          },
          description: description,
          publisher: {
            "@type": "Organization",
            name: "Startupedia",
            url: url+"/",
            logo: {
              "@type": "ImageObject",
              "@id": url + "/#logo",
              url:
                "https://startupedia.net/static/site-logo-a1aeacccc0ab8eda7d7f98e85d360c82.png",
              width: 1000,
              height: 1000,
              caption: "Startupedia",
            },
            image: { "@id": url + "/#logo" },
            brand: "Startupedia",
            sameAs: [
              "https://www.youtube.com/channel/UCOGxgHssFrABTVCDS_OEkOg?sub_confirmation=1",
              "https://twitter.com/startupedianet",
            ],
          },
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": url + "/#webpage",
          },
        }
      }
      break
  }

  return (
    <>
      <Helmet>
        {/* General tags */}
        <html lang={lang} />
        <title>{title} · Startupedia</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />,
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        {
          <link
            rel="canonical"
            href={`https://startupedia.net${paths[lang]}/`}
          />
        }
        {paths.hasOwnProperty("br") && (
          <link
            rel="alternate"
            href={`https://startupedia.net${paths.br}/`}
            hreflang="pt-BR"
          />
        )}
        {paths.hasOwnProperty("de") && (
          <link
            rel="alternate"
            href={`https://startupedia.net${paths.de}/`}
            hreflang="de"
          />
        )}
        {paths.hasOwnProperty("es") && (
          <link
            rel="alternate"
            href={`https://startupedia.net${paths.es}/`}
            hreflang="es"
          />
        )}
        {paths.hasOwnProperty("it") && (
          <link
            rel="alternate"
            href={`https://startupedia.net${paths.it}/`}
            hreflang="it"
          />
        )}
        {paths.hasOwnProperty("fr") && (
          <link
            rel="alternate"
            href={`https://startupedia.net${paths.fr}/`}
            hreflang="fr"
          />
        )}
        {/* Favicon stuff from realfavicongenerator.net */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ffffff" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        {/* OpenGraph tags */}
        <meta
          property="og:url"
          content={`https://startupedia.net${paths[lang]}/`}
        />
        {paths[lang].includes("/blog/") ||
        paths[lang].includes("/what-is/") ||
        paths[lang].includes("/quest-ce-que/") ||
        paths[lang].includes("/o-que-e/") ||
        paths[lang].includes("/que-es/") ||
        paths[lang].includes("/che-cose/") ||
        paths[lang].includes("/was-ist/") ||
        paths[lang].includes("/quien-es/") ||
        paths[lang].includes("/chi-e/") ||
        paths[lang].includes("/wer-ist/") ||
        paths[lang].includes("/chem-e/") ||
        paths[lang].includes("/qui-est/")
        ? (
          <meta property="og:type" content="article" />
        ) : (
          <meta property="og:type" content="website" />
        )}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {image[1] === "/" && <meta property="og:image" content={`https:${image}`} />}
        {image[1] !== "/" && <meta property="og:image" content={`https://startupedia.net${image}`} />}
        <meta property="fb:app_id" content="586749525524342" />
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@startupedianet" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`https:${image}`} />
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>

      <Navigation lang={lang} />
      {children}
      <Footer lang={lang} />
    </>
  )
}

function getLangName(lang) {
  switch (lang) {
    case "es":
      return "Spanish"
    case "de":
      return "German"
    case "br":
      return "Portuguese"
    case "it":
      return "Italian"
    case "fr":
      return "French"
  }
}

function getContact(lang) {
  const url = "https://startupedia.net/"
  switch (lang) {
    case "es":
      return url + "contacto/"
    case "de":
      return url + "de/kontakt/"
    case "br":
      return url + "br/contato/"
    case "it":
      return url + "it/contatto/"
    case "fr":
      return url + "fr/contactez/"
  }
}
